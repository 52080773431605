// Built-ins
import React from "react";

// Modules
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";

// CSS
import "react-tabs/style/react-tabs.css";

// Custom Made Components
import NavigationBar from "../../core/navigation_bar/navigation_bar";
import Footer from "../../core/footer/footer";
import CoachOperatorOverView from "./coach_operator_overview/coach_operator_overview";
import CoachOperatorCoaches from "./coach_operator_coaches/coach_operator_coaches";
import Gallery from "../../core/gallery/gallery";

class CoachOperator extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      coach_operator: {},
    };
  }

  render() {
    return (
      <div>
        <NavigationBar />
        <Tabs>
          <TabList>
            <Tab> Overview </Tab>
            <Tab> Coaches </Tab>
            <Tab> Gallery </Tab>
          </TabList>
          <TabPanel>
            <CoachOperatorOverView />
          </TabPanel>
          <TabPanel>
            <CoachOperatorCoaches />
          </TabPanel>
          <TabPanel>
            <Gallery
              object={this.state.coach_operator}
              object_type={"Coach Operator"}
            />
          </TabPanel>
        </Tabs>
        <Footer />
      </div>
    );
  }
}

export default CoachOperator;
