// Built-ins
import React from "react";

// Modules
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";

// CSS
import "react-tabs/style/react-tabs.css";

// Custom Made Components
import NavigationBar from "../../core/navigation_bar/navigation_bar";
import Footer from "../../core/footer/footer";
import HotelOverView from "./hotel_overview/hotel_overview";
import Gallery from "../../core/gallery/gallery";
import HotelAmenities from "./hotel_amenities/hotel_amenities";

// hotel page Class
class Hotel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hotel: {},
    };
  }

  render() {
    return (
      <div>
        <NavigationBar />
        <Tabs>
          <TabList>
            <Tab>Overview</Tab>
            <Tab>Gallery</Tab>
            <Tab>Amenities</Tab>
          </TabList>
          <TabPanel>
            <HotelOverView />
          </TabPanel>
          <TabPanel>
            <Gallery object={this.state.hotel} object_type={"Hotel"} />
          </TabPanel>
          <TabPanel>
            <HotelAmenities />
          </TabPanel>
        </Tabs>
        <Footer />
      </div>
    );
  }
}

export default Hotel;
