// Built-ins
import React from "react";

// Modules
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";

// CSS
import "react-tabs/style/react-tabs.css";

// Custom Made Components
import NavigationBar from "../../core/navigation_bar/navigation_bar";
import Footer from "../../core/footer/footer";
import AttractionOverView from "./attraction_overview/attraction_overview";
import Gallery from "../../core/gallery/gallery";

// attraction page Class
class Attraction extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      attraction: {},
    };
  }

  render() {
    return (
      <div>
        <NavigationBar />
        <Tabs>
          <TabList>
            <Tab>Overview</Tab>
            <Tab>Gallery</Tab>
          </TabList>
          <TabPanel>
            <AttractionOverView />
          </TabPanel>
          <TabPanel>
            <Gallery
              object={this.state.attraction}
              object_type={"Attraction"}
            />
          </TabPanel>
        </Tabs>
        <Footer />
      </div>
    );
  }
}

export default Attraction;
