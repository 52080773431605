// Built-ins
import React from "react";

// Modules
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";

// CSS
import "react-tabs/style/react-tabs.css";

// Custom Made Components
import NavigationBar from "../../core/navigation_bar/navigation_bar";
import Footer from "../../core/footer/footer";
import AgentOverView from "./agent_overview/agent_overview";
import AgentGroups from "./agent_groups/agent_groups";
import Gallery from "../../core/gallery/gallery";

class Agent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      agent: {},
    };
  }

  render() {
    return (
      <div>
        <NavigationBar />
        <Tabs>
          <TabList>
            <Tab>Overview</Tab>
            <Tab>Gallery</Tab>
            <Tab>Groups</Tab>
          </TabList>
          <TabPanel>
            <AgentOverView />
          </TabPanel>
          <TabPanel>
            <Gallery object={this.state.agent} object_type={"Agent"} />
          </TabPanel>
          <TabPanel>
            <AgentGroups />
          </TabPanel>
        </Tabs>
        <Footer />
      </div>
    );
  }
}

export default Agent;
