// Built-ins
import React from "react";

// Modules
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";

// CSS
import "react-tabs/style/react-tabs.css";

// Custom Made Components
import NavigationBar from "../../core/navigation_bar/navigation_bar";
import Footer from "../../core/footer/footer";
import GroupLeaderOverView from "./group_leader_overview/group_leader_overview";
import Gallery from "../../core/gallery/gallery";

class GroupLeader extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      group_leader: {},
    };
  }

  render() {
    return (
      <div>
        <NavigationBar />
        <Tabs>
          <TabList>
            <Tab> Overview </Tab>
            <Tab> Gallery </Tab>
          </TabList>
          <TabPanel>
            <GroupLeaderOverView />
          </TabPanel>
          <TabPanel>
            <Gallery
              object={this.state.group_leader}
              object_type={"Group Leader"}
            />
          </TabPanel>
        </Tabs>
        <Footer />
      </div>
    );
  }
}

export default GroupLeader;
