// Built-ins
import { useState } from "react";

// CSS
import "react-phone-number-input/style.css";

// Icons / Images
import { BiPlus } from "react-icons/bi";
import { BsInfoSquare } from "react-icons/bs";
import { AiOutlineWarning, AiOutlineCheckCircle } from "react-icons/ai";
import { AiOutlinePlusSquare } from "react-icons/ai";
import { FaMinus } from "react-icons/fa";

// Modules / Functions
import PhoneInput from "react-phone-input-2";
import axios from "axios";
import Swal from "sweetalert2";
import { Modal, Col, Form, Row, Spinner } from "react-bootstrap";
import { Button } from "semantic-ui-react";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import { Grid } from "semantic-ui-react";

// Global Variables
import { headers } from "../../global_vars";

// Variables
window.Swal = Swal;

let warningStyle = {
  fontSize: 18,
  marginRight: 6,
};

let checkStyle = {
  fontSize: 18,
  marginRight: 6,
};

const GET_COUNTRIES = "https://groupplan.gr/api/view/get_all_countries/";
const ADD_CLIENT = "https://groupplan.gr/api/view/add_client/";

const allowAlpha = (value) => {
  return value.replace(/[^\w\s.\-/&\u4e00-\u9eff]{1,20}$/g, "");
};

function AddClientModal(props) {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [Name, setName] = useState("");
  const [Country, setCountry] = useState("");
  let [AllCountries, setAllCountries] = useState([]);
  const [Address, setAddress] = useState("");
  const [tel, setTel] = useState("");
  const [tel2, setTel2] = useState("");
  const [tel3, setTel3] = useState("");
  const [email, setEmail] = useState("");
  let [loaded, setLoaded] = useState(false);
  let [abbreviation, setAbbrevation] = useState("");
  const [Address2, setAddress2] = useState("");
  const [showAddress2, setShowAddress2] = useState(false);
  const [postal, setPostal] = useState("");
  const [showTel2, setShowTel2] = useState(false);
  const [showTel3, setShowTel3] = useState(false);

  const getCountries = () => {
    axios
      .get(GET_COUNTRIES, {
        headers: headers,
      })
      .then((res) => {
        setAllCountries(res.data.all_countries);
        setLoaded(true);
      });
  };

  const createNewClient = () => {
    axios({
      method: "post",
      url: ADD_CLIENT,
      headers: headers,
      data: {
        name: Name,
        abbreviation: abbreviation,
        country: Country,
        address: Address,
        tel: tel,
        tel2: tel2,
        tel3: tel3,
        email: email,
        address2: Address2,
        postal: postal,
      },
    })
      .then((res) => {
        if (props.redir) {
          window.location.href =
            "/data_management/client/" + res.data.new_client_id;
        } else {
          props.set_client(Name);
        }
      })

      .catch((e) => {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: e.response.data.errormsg,
        });
      });
  };

  return (
    <>
      <Button
        color="green"
        style={{ margin: 20 }}
        onClick={() => {
          handleShow();
          getCountries();
          setName("");
          setCountry("");
          setAddress("");
          setTel("");
          setTel2("");
          setTel3("");
          setEmail("");
          setCountry("");
        }}
      >
        <BiPlus
          style={{ color: "white", fontSize: "1.3em", marginRight: "0.3em" }}
        />
        Create new Client
      </Button>
      <Modal
        show={show}
        size="lg"
        onHide={handleClose}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title> Create new Client </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row className="justify-content-md-center">
            <Col>
              <Form>
                <Form.Group>
                  <Form.Label>Name:</Form.Label>
                  <Form.Control
                    maxLength="50"
                    style={{ width: 400 }}
                    onChange={(e) =>
                      setName(allowAlpha(e.currentTarget.value).toUpperCase())
                    }
                    value={Name}
                  />
                  <Form.Label>Address Line 1: </Form.Label>
                  <Form.Control
                    type="text"
                    style={{ width: "90%", display: "inline" }}
                    onChange={(e) => setAddress(e.target.value.toUpperCase())}
                    value={Address}
                  />
                  {!showAddress2 && (
                    <div style={{ float: "right" }}>
                      Add Address Line 2.
                      <AiOutlinePlusSquare
                        className="plus-icon"
                        onClick={() => setShowAddress2(true)}
                      />
                    </div>
                  )}
                </Form.Group>
                {showAddress2 && (
                  <>
                    <Form.Group>
                      <Form.Label>Address Line 2: </Form.Label>
                      <Form.Control
                        type="text"
                        style={{ width: "90%", display: "inline" }}
                        onChange={(e) =>
                          setAddress2(e.target.value.toUpperCase())
                        }
                        value={Address2}
                      />
                      <FaMinus
                        className="minus-icon"
                        onClick={() => setShowAddress2(false)}
                        style={{ float: "right" }}
                      />
                    </Form.Group>
                  </>
                )}

                <Form.Group>
                  <Form.Label>Postal / Zip code: </Form.Label>
                  <Form.Control
                    type="text"
                    style={{ width: 200 }}
                    onChange={(e) => setPostal(e.target.value)}
                    value={postal}
                  />
                </Form.Group>

                <Form.Group>
                  <Grid columns={2}>
                    <Grid.Row style={{ marginLeft: 0, paddingTop: 0 }}>
                      <Grid.Column style={{ paddingTop: 0 }}>
                        <Form.Label>Tel: </Form.Label>
                        <PhoneInput
                          international
                          maxLength={20}
                          defaultCountry="GR"
                          countryCallingCodeEditable={false}
                          key={Country}
                          country={
                            Country.code ? Country.code.toLowerCase() : ""
                          }
                          value={tel}
                          onChange={setTel}
                        />
                      </Grid.Column>
                      <Grid.Column>
                        {!showTel2 && (
                          <div style={{ marginTop: 30 }}>
                            Add Tel 2.
                            <AiOutlinePlusSquare
                              className="plus-icon"
                              onClick={() => setShowTel2(true)}
                            />
                          </div>
                        )}
                      </Grid.Column>
                    </Grid.Row>

                    {showTel2 && (
                      <>
                        <Grid.Column style={{ margin: 0, paddingTop: 0 }}>
                          <Form.Label>Tel. 2: </Form.Label>
                          <PhoneInput
                            international
                            maxLength={20}
                            defaultCountry="GR"
                            countryCallingCodeEditable={false}
                            key={Country}
                            country={
                              Country.code ? Country.code.toLowerCase() : ""
                            }
                            value={tel2}
                            onChange={setTel2}
                          />
                        </Grid.Column>
                        <Grid.Column>
                          {!showTel3 && (
                            <>
                              <div style={{ marginTop: 16 }}>
                                Remove Tel 2.
                                <FaMinus
                                  className="minus-icon"
                                  style={{ marginRight: 50 }}
                                  onClick={() => setShowTel2(false)}
                                />
                                Add Tel 3.
                                <AiOutlinePlusSquare
                                  className="plus-icon"
                                  onClick={() => setShowTel3(true)}
                                />
                              </div>
                            </>
                          )}
                        </Grid.Column>
                      </>
                    )}

                    {showTel3 && (
                      <>
                        <Grid.Column style={{ margin: 0, paddingTop: 0 }}>
                          <Form.Label>Tel. 3: </Form.Label>
                          <PhoneInput
                            international
                            maxLength={20}
                            defaultCountry="GR"
                            countryCallingCodeEditable={false}
                            key={Country}
                            country={
                              Country.code ? Country.code.toLowerCase() : ""
                            }
                            value={tel3}
                            onChange={setTel3}
                          />
                        </Grid.Column>
                        <Grid.Column>
                          <div style={{ marginTop: 16 }}>
                            Remove Tel 3.
                            <FaMinus
                              className="minus-icon"
                              onClick={() => setShowTel3(false)}
                            />
                          </div>
                        </Grid.Column>
                      </>
                    )}
                  </Grid>
                  <Form.Label>Email</Form.Label>
                  <Form.Control
                    maxLength="100"
                    style={{ width: 300 }}
                    onChange={(e) => {
                      setEmail(e.target.value.toLowerCase());
                    }}
                    value={email}
                  />
                  <br />
                  <Form.Label>Abbrevation</Form.Label>
                  <br />
                  <Form.Control
                    maxLength="5"
                    style={{ width: 200 }}
                    onChange={(e) => {
                      setAbbrevation(e.target.value.toUpperCase());
                    }}
                    value={abbreviation}
                    placeholder="ABC"
                  />
                  <br />
                  <Form.Label>Country</Form.Label>
                  <br />
                  <Autocomplete
                    options={AllCountries}
                    className={"select_airport"}
                    onChange={(e) => {
                      setCountry(e.target.innerText);
                    }}
                    getOptionLabel={(option) => option.name}
                    style={{ width: 300, margin: 0 }}
                    disabled={!loaded}
                    disableClearable
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Select country"
                        variant="outlined"
                      />
                    )}
                  />
                  {loaded ? (
                    ""
                  ) : (
                    <Spinner
                      animation="border"
                      variant="info"
                      size="sm"
                      style={{ position: "fixed", margin: 20 }}
                    />
                  )}
                </Form.Group>
              </Form>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <small className="mr-auto">
            <BsInfoSquare
              style={{
                color: "#F3702D",
                fontSize: "1.5em",
                marginRight: "0.5em",
              }}
            />
            All fields except Tel2 and Tel3 are required to create a client.
            {Name.length < 2 ||
            Country.length < 3 ||
            Address.length < 5 ||
            abbreviation.length === 0 ||
            !postal ||
            !tel ? (
              <>
                <ul
                  className="mr-auto"
                  style={{ margin: 0, padding: 0, marginTop: 10, color: "red" }}
                >
                  <li>
                    {Name.length < 2 ? (
                      <>
                        <AiOutlineWarning style={warningStyle} />
                        Fill The Name Field.
                      </>
                    ) : (
                      ""
                    )}
                  </li>
                  <li>
                    {Address.length < 5 ? (
                      <>
                        <AiOutlineWarning style={warningStyle} />
                        Fill The Address Field.
                      </>
                    ) : (
                      ""
                    )}
                  </li>
                  <li>
                    {!tel ? (
                      <>
                        <AiOutlineWarning style={warningStyle} />
                        Fill The Tel Field.
                      </>
                    ) : (
                      ""
                    )}
                  </li>
                  <li>
                    {abbreviation.length < 3 ? (
                      <>
                        <AiOutlineWarning style={warningStyle} />
                        Fill The Abbreviation Field.
                      </>
                    ) : (
                      ""
                    )}
                  </li>
                  <li>
                    {Country.length < 3 ? (
                      <>
                        <AiOutlineWarning style={warningStyle} />
                        Fill The Country Field.
                      </>
                    ) : (
                      ""
                    )}
                  </li>
                  <li>
                    {!postal ? (
                      <>
                        <AiOutlineWarning style={warningStyle} /> Fill The
                        Postal / Zip code Field.
                      </>
                    ) : (
                      ""
                    )}
                  </li>
                </ul>
              </>
            ) : (
              <>
                <ul
                  className="mr-auto"
                  style={{
                    margin: 0,
                    padding: 0,
                    marginTop: 10,
                    color: "green",
                  }}
                >
                  <li>
                    <AiOutlineCheckCircle style={checkStyle} />
                    Validated
                  </li>
                </ul>
              </>
            )}
          </small>
          <Button color="red" onClick={handleClose}>
            Close
          </Button>
          <Button
            color="green"
            onClick={() => {
              handleClose();
              createNewClient();
            }}
            disabled={
              Name.length < 2 ||
              Country.length < 3 ||
              Address.length < 5 ||
              !postal ||
              abbreviation.length === 0 ||
              !tel
            }
          >
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default AddClientModal;
