// Built-ins
import React from "react";

// Modules
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";

// CSS
import "react-tabs/style/react-tabs.css";

// Custom Made Components
import NavigationBar from "../../core/navigation_bar/navigation_bar";
import Footer from "../../core/footer/footer";
import TeleferikCompanyOverView from "./teleferik_company_overview/teleferik_company_overview";

class TeleferikCompany extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      teleferik_company: {},
    };
  }

  render() {
    return (
      <div>
        <NavigationBar />
        <Tabs>
          <TabList>
            <Tab> Overview </Tab>
          </TabList>
          <TabPanel>
            <TeleferikCompanyOverView />
          </TabPanel>
        </Tabs>
        <Footer />
      </div>
    );
  }
}

export default TeleferikCompany;
