// Built-ins
import React, { useState } from "react";

// Icons / Images
import { FiEdit2 } from "react-icons/fi";
import { AiOutlineWarning, AiOutlineCheckCircle } from "react-icons/ai";

// Modules / Functions
import { Modal } from "react-bootstrap";
import { Button } from "semantic-ui-react";
import axios from "axios";
import Swal from "sweetalert2";

// Global Variables
import { headers } from "../../../../global_vars";

const currencies = {
  'EUR': '€',
  'GBP': '£',
  'USD': '$',
  'CAD': '$',
  'AUD': '$',
  'CHF': '₣',
  'JPY': '¥',
  'NZD': '$',
  'CNY': '¥',
  'SGD': '$',
}

// Variables
window.Swal = Swal;

const CHANGE_PRICE_PER_PERSON = "https://groupplan.gr/api/groups/change_price_per_person_td/";

let warningStyle = {
  fontSize: 18,
  marginRight: 6,
};

let checkStyle = {
  fontSize: 18,
  marginRight: 6,
};

function ChangePricePerPerson(props) {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [pricePerPerson, setPricePerPerson] = useState();
  const [currency, setCurrency] = useState("EUR");

  const updatePricePerPerson = () => {
    props.updateIsLoaded();
    axios({
      method: "post",
      url: CHANGE_PRICE_PER_PERSON + props.group.refcode,
      headers: headers,
      data: {
        td_id: props.td_id,
        price_per_person: pricePerPerson ? currencies[currency] + ' ' + String(pricePerPerson) : null,
      },
    })
      .then((res) => {
        props.update_state(res.data.model);
        props.updateIsLoaded();
      })
      .catch((e) => {
        props.updateIsLoaded();
        Swal.fire({
          icon: "error",
          title: "Error",
          text: e.response.data.errormsg,
        });
      });
  };

  const handleBlur = (e) => {
    let value = parseFloat(e.target.value);
    if (!isNaN(value)) {
      value = value.toFixed(2);
      setPricePerPerson(value);
    } else {
      setPricePerPerson("");
    }
  };
  
  return (
    <>
      <FiEdit2
        title={"Edit Price Per Person"}
        id={"edit_refcode"}
        className={"edit_icon"}
        onClick={() => {
          handleShow();
        }}
      />
      <Modal
        show={show}
        onHide={handleClose}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Change Option Date for {props.date}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div style={{ marginLeft: 20 }}>
            Price Per Person :
            <select
              className="form-control"
              onChange={(e) => setCurrency(e.target.value)}
              style={{ marginBottom: 10, width: 200 }}
              value={currency}
            >
              <option value="EUR"> € Euro (EUR) </option>
              <option value="GBP"> £ Pound Sterling (GBP) </option>
              <option value="USD"> $ US Dollar (USD) </option>
              <option value="CAD"> $ Canadian Dollar (CAD) </option>
              <option value="AUD"> $ Australian Dollar (AUD) </option>
              <option value="CHF"> ₣ Swiss Franc (CHF) </option>
              <option value="JPY"> ¥ Japanese Yen (JPY) </option>
              <option value="NZD"> $ New Zealand Dollar (NZD) </option>
              <option value="CNY"> ¥ Chinese Yuan (CNY) </option>
              <option value="SGD"> $ Singapore Dollar (SGD) </option>
            </select>
            <input
              style={{ width: 200 }}
              type="text"
              value={pricePerPerson}
              onBlur={handleBlur}
              onInput={(e) => {
                // Allow only numbers and up to 2 decimal places
                e.target.value = e.target.value.replace(/[^0-9.]/g, ""); // Remove non-numeric characters
                e.target.value = e.target.value.replace(/(\.\d{2}).*$/, "$1"); // Keep only up to 2 decimal places
              }}
              className="form-control"
              onChange={(e) => setPricePerPerson(e.currentTarget.value)}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <small className="mr-auto">
            {!pricePerPerson ? (
              <>
                <ul className="mr-auto" style={{ margin: 0, padding: 0, marginTop: 10, color: "red" }}>
                  <li>
                    {!pricePerPerson ? (
                      <> <AiOutlineWarning style={warningStyle} />Fill the price per person field. </>
                    ) : (
                      ""
                    )}
                  </li>
                </ul>
              </>
            ) : (
              <>
                <ul
                  className="mr-auto"
                  style={{
                    margin: 0,
                    padding: 0,
                    marginTop: 10,
                    color: "green",
                  }}
                >
                  <li>
                    <AiOutlineCheckCircle style={checkStyle} /> Validated
                  </li>
                </ul>
              </>
            )}
          </small>

          <Button color="red" onClick={handleClose}>
            Close
          </Button>
          <Button
            color="green"
            disabled={!pricePerPerson}
            onClick={() => {
              handleClose();
              updatePricePerPerson();
            }}
          >
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default ChangePricePerPerson;
