// Built-ins
import React from "react";

// Modules
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";

// CSS
import "react-tabs/style/react-tabs.css";

// Custom Made Components
import NavigationBar from "../../core/navigation_bar/navigation_bar";
import Footer from "../../core/footer/footer";
import RepairShopOverView from "./repair_shop_overview/repair_shop_overview";

class RepairShop extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      repair_shop: {},
    };
  }

  render() {
    return (
      <div>
        <NavigationBar />
        <div id="dm_tab">
          <Tabs>
            <TabList>
              <Tab> Overview </Tab>
            </TabList>
            <TabPanel>
              <RepairShopOverView />
            </TabPanel>
          </Tabs>
        </div>
        <Footer />
      </div>
    );
  }
}

export default RepairShop;
