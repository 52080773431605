// Built-ins
import React, { useState } from "react";

// Icons / Images
import { FiEdit2 } from "react-icons/fi";
import { CgDanger } from "react-icons/cg";
import { AiOutlineWarning, AiOutlineCheckCircle } from "react-icons/ai";

// Modules / Functions
import { Modal, ListGroup, Spinner, Form } from "react-bootstrap";
import { Button, Checkbox } from "semantic-ui-react";
import axios from "axios";
import DatePicker from "react-date-picker";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import moment from "moment";
import Swal from "sweetalert2";

// Custom Made Components
import AddHotelModal from "../../../../modals/create/add_hotel_modal";

// Global Variables
import { headers } from "../../../../global_vars";

// Variables
window.Swal = Swal;

const CHANGE_HOTEL = "https://groupplan.gr/api/groups/change_hotel/";
const GET_HOTELS = "https://groupplan.gr/api/view/get_all_hotels/";
const GET_HOTEL = "https://groupplan.gr/api/view/get_hotel/";

const currencies = {
  'EUR': '€',
  'GBP': '£',
  'USD': '$',
  'CAD': '$',
  'AUD': '$',
  'CHF': '₣',
  'JPY': '¥',
  'NZD': '$',
  'CNY': '¥',
  'SGD': '$',
}

let warningStyle = {
  fontSize: 18,
  marginRight: 6,
};

let checkStyle = {
  fontSize: 18,
  marginRight: 6,
};

function ChangeHotel(props) {
  const [show, setShow] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [Hotel, setHotel] = useState(props.hotel ? props.hotel.name : "");
  const [UpdateRestOfDays, setUpdateRestOfDays] = React.useState(false);
  let [AllHotels, setAllHotels] = useState([]);
  const [contract, setContract] = useState({ id: 0 });
  const [useRooms, setUseRooms] = useState(false);

  const [sgl, setSGL] = useState(0);
  const [dbl, setDBL] = useState(0);
  const [twin, setTwin] = useState(0);
  const [trpl, setTriple] = useState(0);
  const [quad, setQuad] = useState(0);
  const [optionDate, setOptionDate] = useState();
  const [pricePerPerson, setPricePerPerson] = useState();
  const [currency, setCurrency] = useState("EUR");

  const updateHotel = () => {
    props.updateIsLoaded();
    axios({
      method: "post",
      url: CHANGE_HOTEL + props.group.refcode,
      headers: headers,
      data: {
        type: "Group",
        td_id: props.td_id,
        hotel: Hotel,
        update_rest_of_days: UpdateRestOfDays,
        sgl: sgl,
        dbl: dbl,
        twin: twin,
        trpl: trpl,
        quad: quad,
        price_per_person: pricePerPerson ? currencies[currency] + ' ' + String(pricePerPerson) : null,
        option_date: optionDate ? moment(optionDate).format("YYYY-MM-DD") : null,
      },
    })
      .then((res) => {
        props.update_state(res.data.model);
        props.updateIsLoaded();
      })
      .catch((e) => {
        props.updateIsLoaded();
        Swal.fire({
          icon: "error",
          title: "Error",
          text: e.response.data.errormsg,
        });
      });
  };

  const getHotels = () => {
    axios
      .get(GET_HOTELS, {
        headers: headers,
      })
      .then((res) => {
        setAllHotels(res.data.all_hotels.map((hotel) => hotel.name));
        setLoaded(true);
      });
  };

  const handleCheckBox = () => {
    setUpdateRestOfDays(!UpdateRestOfDays);
  };

  const handleCheckBoxRooms = () => {
    setUseRooms(!useRooms);
  };

  const getHotelObject = (hotel) => {
    axios
      .get(GET_HOTEL + Hotel, {
        headers: headers,
        params: {
          hotel_name: hotel,
          date: props.date,
        },
      })
      .then((res) => {
        setContract(res.data.contract);
        setLoaded(true);
      });
  };

  const handleBlur = (e) => {
    let value = parseFloat(e.target.value);
    if (!isNaN(value)) {
      value = value.toFixed(2);
      setPricePerPerson(value);
    } else {
      setPricePerPerson("");
    }
  };

  return (
    <>
      <FiEdit2
        title={"Edit Hotel"}
        id={"edit_refcode"}
        className={"edit_icon"}
        onClick={() => {
          handleShow();
          getHotels();
        }}
      />
      <Modal
        show={show}
        onHide={handleClose}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Change Hotel for {props.date}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ListGroup>
            <ListGroup.Item>
              <Autocomplete
                options={AllHotels}
                className={"select_airport"}
                onChange={(e) => {
                  setHotel(e.target.innerText);
                  getHotelObject(e.target.innerText);
                }}
                style={{ width: 300 }}
                disabled={!loaded}
                value={Hotel}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Select hotel"
                    variant="outlined"
                  />
                )}
              />
              <div style={{ float: "right" }}>
                <AddHotelModal redir={false} set_hotel={(e) => setHotel(e)} />
              </div>
              <hr/>
              <div style={{ marginLeft: 20, marginTop: 20 }}>
                Select Option Date :
                <DatePicker
                  wrapperClassName="datePicker"
                  name="date"
                  onChange={(e) => setOptionDate(e)}
                  value={optionDate}
                  format="dd/MM/yyyy"
                  minDate={new Date()}
                />
              </div>
              <hr/>
              <div style={{ marginLeft: 20, marginTop: 20 }}>
                Price Per Person :
                <select
                  className="form-control"
                  onChange={(e) => setCurrency(e.target.value)}
                  style={{ marginBottom: 10, width: 200 }}
                  value={currency}
                >
                  <option value="EUR"> € Euro (EUR) </option>
                  <option value="GBP"> £ Pound Sterling (GBP) </option>
                  <option value="USD"> $ US Dollar (USD) </option>
                  <option value="CAD"> $ Canadian Dollar (CAD) </option>
                  <option value="AUD"> $ Australian Dollar (AUD) </option>
                  <option value="CHF"> ₣ Swiss Franc (CHF) </option>
                  <option value="JPY"> ¥ Japanese Yen (JPY) </option>
                  <option value="NZD"> $ New Zealand Dollar (NZD) </option>
                  <option value="CNY"> ¥ Chinese Yuan (CNY) </option>
                  <option value="SGD"> $ Singapore Dollar (SGD) </option>
                </select>
                <input
                  style={{ width: 200 }}
                  type="text"
                  value={pricePerPerson}
                  onInput={(e) => {
                    // Allow only numbers and up to 2 decimal places
                    e.target.value = e.target.value.replace(/[^0-9.]/g, ""); // Remove non-numeric characters
                    e.target.value = e.target.value.replace(/(\.\d{2}).*$/, "$1"); // Keep only up to 2 decimal places
                  }}
                  className="form-control"
                  onBlur={handleBlur}
                  onChange={(e) => setPricePerPerson(e.currentTarget.value)}
                />
              </div>
              <hr/>
              {loaded ? (
                ""
              ) : (
                <Spinner
                  animation="border"
                  variant="info"
                  size="sm"
                  style={{ position: "fixed", marginTop: 20, marginLeft: 10 }}
                />
              )}
              <div style={{ marginLeft: 20, marginTop: 20 }}>
                <Checkbox
                  label={"All upcoming days?"}
                  value={UpdateRestOfDays}
                  onChange={handleCheckBox}
                />
              </div>
              <hr />

              {Hotel ? (
                contract ? (
                  <>
                    Active contract found for : {Hotel}
                    At {props.date}
                    <br />
                    Named: {contract.name}
                    <hr />
                    <Checkbox
                      label={"I want to add rooms from this contract."}
                      value={useRooms}
                      onChange={handleCheckBoxRooms}
                    />
                    {useRooms ? (
                      <>
                        <ul>
                          <li>
                            <div className="room_span"> Single </div>
                            <Form.Control
                              type="number"
                              style={{
                                width: 60,
                                display: "inline",
                                maxHeight: 30,
                                marginLeft: 20,
                              }}
                              value={sgl}
                              disabled={
                                contract.room_contract.filter(
                                  (room) =>
                                    room.date === props.date &&
                                    room.room_type === "SGL" &&
                                    room.available
                                ).length === 0
                              }
                              onChange={(e) => {
                                const maxValue = contract.room_contract.filter(
                                  (room) => room.date === props.date && room.room_type === "SGL" && room.available).length;
                                const enteredValue = parseInt( e.target.value, 10);
                                const newValue = isNaN(enteredValue) ? 0 : Math.min(enteredValue, maxValue);
                                setSGL(newValue);
                              }}
                              min={0}
                              max={
                                contract.room_contract.filter(
                                  (room) =>
                                    room.date === props.date &&
                                    room.room_type === "SGL" &&
                                    room.available
                                ).length
                              }
                            />
                            {" / "}
                            <Form.Control
                              type="number"
                              disabled
                              style={{
                                width: 60,
                                display: "inline",
                                maxHeight: 30,
                              }}
                              value={
                                contract.room_contract.filter(
                                  (room) =>
                                    room.date === props.date &&
                                    room.room_type === "SGL" &&
                                    room.available
                                ).length
                              }
                            />
                          </li>
                          <li>
                            <div className="room_span"> Double </div>
                            <Form.Control
                              type="number"
                              style={{
                                width: 60,
                                display: "inline",
                                maxHeight: 30,
                                marginLeft: 20,
                              }}
                              value={dbl}
                              disabled={
                                contract.room_contract.filter(
                                  (room) =>
                                    room.date === props.date &&
                                    room.room_type === "DBL" &&
                                    room.available
                                ).length === 0
                              }
                              onChange={(e) => {
                                const maxValue = contract.room_contract.filter(
                                  (room) =>
                                    room.date === props.date &&
                                    room.room_type === "DBL" &&
                                    room.available
                                ).length;
                                const enteredValue = parseInt(
                                  e.target.value,
                                  10
                                );
                                const newValue = isNaN(enteredValue)
                                  ? 0
                                  : Math.min(enteredValue, maxValue);
                                setDBL(newValue);
                              }}
                              min={0}
                              max={
                                contract.room_contract.filter(
                                  (room) =>
                                    room.date === props.date &&
                                    room.room_type === "DBL" &&
                                    room.available
                                ).length
                              }
                            />
                            {" / "}
                            <Form.Control
                              type="number"
                              disabled
                              style={{
                                width: 60,
                                display: "inline",
                                maxHeight: 30,
                              }}
                              value={
                                contract.room_contract.filter(
                                  (room) =>
                                    room.date === props.date &&
                                    room.room_type === "DBL" &&
                                    room.available
                                ).length
                              }
                            />
                          </li>

                          <li>
                            <div className="room_span"> Twin </div>
                            <Form.Control
                              type="number"
                              style={{
                                width: 60,
                                display: "inline",
                                maxHeight: 30,
                                marginLeft: 20,
                              }}
                              value={twin}
                              disabled={
                                contract.room_contract.filter(
                                  (room) =>
                                    room.date === props.date &&
                                    room.room_type === "TWIN" &&
                                    room.available
                                ).length === 0
                              }
                              onChange={(e) => {
                                const maxValue = contract.room_contract.filter(
                                  (room) =>
                                    room.date === props.date &&
                                    room.room_type === "TWIN" &&
                                    room.available
                                ).length;
                                const enteredValue = parseInt(
                                  e.target.value,
                                  10
                                );
                                const newValue = isNaN(enteredValue)
                                  ? 0
                                  : Math.min(enteredValue, maxValue);
                                setTwin(newValue);
                              }}
                              min={0}
                              max={
                                contract.room_contract.filter(
                                  (room) =>
                                    room.date === props.date &&
                                    room.room_type === "TWIN" &&
                                    room.available
                                ).length
                              }
                            />
                            {" / "}
                            <Form.Control
                              type="number"
                              disabled
                              style={{
                                width: 60,
                                display: "inline",
                                maxHeight: 30,
                              }}
                              value={
                                contract.room_contract.filter(
                                  (room) =>
                                    room.date === props.date &&
                                    room.room_type === "TWIN" &&
                                    room.available
                                ).length
                              }
                            />
                          </li>
                          <li>
                            <div className="room_span"> Triple </div>
                            <Form.Control
                              type="number"
                              style={{
                                width: 60,
                                display: "inline",
                                maxHeight: 30,
                                marginLeft: 20,
                              }}
                              value={trpl}
                              disabled={
                                contract.room_contract.filter(
                                  (room) =>
                                    room.date === props.date &&
                                    room.room_type === "TRPL" &&
                                    room.available
                                ).length === 0
                              }
                              onChange={(e) => {
                                const maxValue = contract.room_contract.filter(
                                  (room) =>
                                    room.date === props.date &&
                                    room.room_type === "TRPL" &&
                                    room.available
                                ).length;
                                const enteredValue = parseInt(
                                  e.target.value,
                                  10
                                );
                                const newValue = isNaN(enteredValue)
                                  ? 0
                                  : Math.min(enteredValue, maxValue);
                                setTriple(newValue);
                              }}
                              min={0}
                              max={
                                contract.room_contract.filter(
                                  (room) =>
                                    room.date === props.date &&
                                    room.room_type === "TRPL" &&
                                    room.available
                                ).length
                              }
                            />
                            {" / "}
                            <Form.Control
                              type="number"
                              disabled
                              style={{
                                width: 60,
                                display: "inline",
                                maxHeight: 30,
                              }}
                              value={
                                contract.room_contract.filter(
                                  (room) =>
                                    room.date === props.date &&
                                    room.room_type === "TRPL" &&
                                    room.available
                                ).length
                              }
                            />
                          </li>
                          <li>
                            <div className="room_span"> Quad </div>
                            <Form.Control
                              type="number"
                              style={{
                                width: 60,
                                display: "inline",
                                maxHeight: 30,
                                marginLeft: 20,
                              }}
                              value={quad}
                              disabled={
                                contract.room_contract.filter(
                                  (room) =>
                                    room.date === props.date &&
                                    room.room_type === "QUAD" &&
                                    room.available
                                ).length === 0
                              }
                              onChange={(e) => {
                                const maxValue = contract.room_contract.filter(
                                  (room) =>
                                    room.date === props.date &&
                                    room.room_type === "QUAD" &&
                                    room.available
                                ).length;
                                const enteredValue = parseInt(
                                  e.target.value,
                                  10
                                );
                                const newValue = isNaN(enteredValue)
                                  ? 0
                                  : Math.min(enteredValue, maxValue);
                                setQuad(newValue);
                              }}
                              min={0}
                              max={
                                contract.room_contract.filter(
                                  (room) =>
                                    room.date === props.date &&
                                    room.room_type === "QUAD" &&
                                    room.available
                                ).length
                              }
                            />
                            {" / "}
                            <Form.Control
                              type="number"
                              disabled
                              style={{
                                width: 60,
                                display: "inline",
                                maxHeight: 30,
                              }}
                              value={
                                contract.room_contract.filter(
                                  (room) =>
                                    room.date === props.date &&
                                    room.room_type === "QUAD" &&
                                    room.available
                                ).length
                              }
                            />
                          </li>
                        </ul>
                      </>
                    ) : (
                      ""
                    )}
                  </>
                ) : (
                  <>
                    No Active contract found for : {Hotel} at {props.date}
                  </>
                )
              ) : (
                <></>
              )}
            </ListGroup.Item>
          </ListGroup>
        </Modal.Body>
        <Modal.Footer>
          <small className="mr-auto">
            <CgDanger
              style={{ color: "red", fontSize: "1.5em", marginRight: "0.5em" }}
            />
            Updating travelday's hotel will also update the location

            {!pricePerPerson ? (
              <>
                <ul className="mr-auto" style={{ margin: 0, padding: 0, marginTop: 10, color: "red" }}>
                  <li>
                    {!pricePerPerson ? (
                      <> <AiOutlineWarning style={warningStyle} />Fill the price per person field. </>
                    ) : (
                      ""
                    )}
                  </li>
                </ul>
              </>
            ) : (
              <>
                <ul
                  className="mr-auto"
                  style={{
                    margin: 0,
                    padding: 0,
                    marginTop: 10,
                    color: "green",
                  }}
                >
                  <li>
                    <AiOutlineCheckCircle style={checkStyle} /> Validated
                  </li>
                </ul>
              </>
            )}
          </small>
          <Button color="red" onClick={handleClose}>
            Close
          </Button>
          <Button
            color="green"
            disabled={!pricePerPerson}
            onClick={() => {
              handleClose();
              updateHotel();
            }}
          >
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default ChangeHotel;
