// Built-ins
import React from "react";

// Custom made components
import NavigationBar from "../navigation_bar/navigation_bar";
import Footer from "../footer/footer";

// Modules / Functions
import Paper from "@material-ui/core/Paper";

// Global Variables
import { pageHeader } from "../../global_vars";

// CSS
import "./terms.css";

// url path = '/terms'
class Terms extends React.Component {
  render() {
    return (
      // Terms and conditions text is generated by chatgpt
      <>
        <NavigationBar />
        {pageHeader("terms")}
        <div className="mainContainer">
          <Paper className="termsPaper" style={{ backgroundColor: "#eee" }}>
            <h1>1) Introduction</h1>
            <br />
            <span>
              <p>
                • Welcome to Group Plan, a closed source tour operating software
                provided by Cosmoplan International Travel LTD. ("Company",
                "we", "us", or "our").
              </p>
              <p>
                • These terms and conditions ("Terms", "Terms and Conditions")
                govern your access to and use of the Group Plan software and all
                related services ("Service" or "Services") provided by the
                Company.
              </p>
            </span>
            <hr />
            <h1>2) Agreement to Terms</h1>
            <br />
            <span>
              <p>
                • By accessing or using the Service, you agree to be bound by
                these Terms.
              </p>
              <p>
                • If you do not agree to these terms, you may not access or use
                the Service.
              </p>
            </span>
            <hr />
            <h1>3) Changes to Terms</h1>
            <br />
            <span>
              <p>
                • We reserve the right to update and change these Terms from
                time to time without notice.
              </p>
              <p>
                • Any new features or updates to the Service shall be subject to
                these Terms.
              </p>
              <p>
                • Your continued use of the Service after any such changes shall
                constitute your acceptance of the new Terms.
              </p>
            </span>
            <hr />
            <h1>4) Use of the Service</h1>
            <br />
            <span>
              <p>
                • You are granted a limited, non-transferable, non-exclusive,
                revocable license to access and use the Service for the purpose
                of creating and managing tour plans for your organization or
                business.
              </p>
              <p>
                • You may not use the Service for any other purpose, including
                any commercial use, without our prior written consent.
              </p>
            </span>
            <hr />
            <h1>5) Proprietary Rights</h1>
            <br />
            <span>
              <p>
                • The Service and its entire contents, features, and
                functionality (including but not limited to all information,
                software, text, displays, images, video, and audio, and the
                design, selection, and arrangement thereof), are owned by the
                Company, its licensors, or other providers of such material, and
                are protected by international copyright, trademark, patent,
                trade secret, and other intellectual property or proprietary
                rights laws.
              </p>
            </span>
            <hr />
            <h1>6) Termination</h1>
            <br />
            <span>
              <p>
                • We reserve the right to terminate or suspend your access to
                the Service at any time, with or without cause, and with or
                without notice.
              </p>
              <p>
                • If you wish to terminate your use of the Service, you may do
                so by ceasing to use the Service.
              </p>
            </span>
            <hr />
            <h1>7) Disclaimer of Warranties</h1>
            <br />
            <span>
              <p>
                • The Service is provided on an "as is" and "as available"
                basis.
              </p>
              <p>
                • The Company makes no representations or warranties of any
                kind, express or implied, as to the operation of the Service or
                the information, content, materials, or products included on the
                Service.
              </p>
            </span>
            <hr />
            <h1>8) Limitation of Liability</h1>
            <br />
            <span>
              <p>
                • In no event shall the Company, its affiliates, or their
                respective officers, directors, employees, agents, or assigns be
                liable for any direct, indirect, incidental, special, punitive,
                or consequential damages arising out of or in any way connected
                with the use of the Service or with the delay or inability to
                use the Service, or for any information, software, products,
                services, and related graphics obtained through the Service,. or
                otherwise arising out of the use of the Service, whether based
                on contract, tort, strict liability, or otherwise.
              </p>
            </span>
            <hr />
            <h1> 9) Governing Law</h1>
            <br />
            <span>
              <p>
                • These Terms and your use of the Service shall be governed by
                and construed in accordance with the laws of Greece, without
                giving effect to any principles of conflicts of law.
              </p>
            </span>
            <hr />
            <h1> 10) Contact Us</h1>
            <br />
            <span>
              <p>
                • If you have any questions about these Terms, please contact us
                at info@cosmoplan.gr.
              </p>
            </span>
            <hr />
            <h1> 10) Additional Terms</h1>
            <br />
            <span>
              <p>
                • Information on this site is posted and updated on a regular
                basis by Cosmoplan.
              </p>
              <p>
                • Cosmoplan does not accept any liability to you or any third
                party for any error or omission on this site.
              </p>
              <p>
                • Cosmoplan cannot guarantee the accuracy and completeness of
                this site.
              </p>
              <p>
                • Cosmoplan may update, change or delete information of this
                site any time and without any prior notice.
              </p>
              <p>
                • Cosmoplan name, logo, any service names, design marks, product
                names, and slogans are Cosmoplan' and its supplier's valuable
                property.
              </p>
              <p>
                • On this site there are information and material such as hotel
                reports, photos, slides, travel guides and maps that are the
                copyright of Cosmoplan and its suppliers.
              </p>
              <p>
                • All information given to this site is for your personal and
                company's use only.
              </p>
              <p>
                • All information is for your clients interest only and must not
                be disclosed to any other third party.
              </p>
              <p>
                • Your access to this site and its information does not give you
                the right and a license to reproduce and use the information and
                the material given, apart from downloading it, only on to your
                company's computers.
              </p>
              <p>
                • You must keep secure all means of identification that
                Cosmoplan supplies to you in order to use services on this site.
              </p>
              <p>
                • When using this site you must supply accurate and not
                misleading information.
              </p>
              <p>
                • Cosmoplan supplies the travel agent - client approved by the
                management, with a password in order the client to be able to
                have access to Cosmoplan's online booking engine and see all
                hotel allotments.
              </p>
              <p>
                • All passwords must be kept by you - the client - secure and
                confidential.
              </p>
              <p>
                • Cosmoplan may modify these site terms & conditions without
                prior notice.
              </p>
              <p>
                • These site terms shall be governed by UK law. Any disputes
                involving this site will be subject to the jurisdiction of the
                Courts in London.
              </p>
            </span>
          </Paper>
        </div>
        <Footer />
      </>
    );
  }
}

export default Terms;
