// Built-ins
import React from "react";

// Modules
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";

// CSS
import "react-tabs/style/react-tabs.css";

// Custom Made Components
import NavigationBar from "../../core/navigation_bar/navigation_bar";
import Footer from "../../core/footer/footer";
import TrainTicketAgencyOverView from "./train_ticket_agency_overview/train_ticket_agency_overview";

class TrainTicketAgency extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      train_ticket_agency: {},
    };
  }

  render() {
    return (
      <div>
        <NavigationBar />
        <Tabs>
          <TabList>
            <Tab> Overview </Tab>
          </TabList>
          <TabPanel>
            <TrainTicketAgencyOverView />
          </TabPanel>
        </Tabs>
        <Footer />
      </div>
    );
  }
}

export default TrainTicketAgency;
