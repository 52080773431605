// Built-ins
import React from "react";

// Modules
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";

// CSS
import "react-tabs/style/react-tabs.css";

// Custom Made Components
import NavigationBar from "../../core/navigation_bar/navigation_bar";
import Footer from "../../core/footer/footer";
import EntertainmentSupplierOverView from "./entertainment_supplier_overview/entertainment_supplier_overview";
import EntertainmentProducts from "./entertainment_supplier_products/entertainment_supplier_products";
import Gallery from "../../core/gallery/gallery";

// entertainment_supplier page Class
class EntertainmentSupplier extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      entertainment_supplier: {},
    };
  }

  render() {
    return (
      <div>
        <NavigationBar />
        <Tabs>
          <TabList>
            <Tab>Overview</Tab>
            <Tab>Gallery</Tab>
            <Tab>Products</Tab>
          </TabList>
          <TabPanel>
            <EntertainmentSupplierOverView />
          </TabPanel>
          <TabPanel>
            <Gallery
              object={this.state.entertainment_supplier}
              object_type={"Entertainment Supplier"}
            />
          </TabPanel>
          <TabPanel>
            <EntertainmentProducts />
          </TabPanel>
        </Tabs>
        <Footer />
      </div>
    );
  }
}

export default EntertainmentSupplier;
