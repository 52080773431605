// Built-ins
import React from "react";

// Modules
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";

// CSS
import "react-tabs/style/react-tabs.css";

// Custom Made Components
import NavigationBar from "../../core/navigation_bar/navigation_bar";
import Footer from "../../core/footer/footer";
import FerryTicketAgencyOverView from "./ferry_ticket_agency_overview/ferry_ticket_agency_overview";
import FerryTicketAgencyRoutes from "./ferry_ticket_agency_routes/ferry_ticket_agency_routes";

class FerryTicketAgency extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ferry_ticket_agency: {},
    };
  }

  render() {
    return (
      <div>
        <NavigationBar />
        <Tabs>
          <TabList>
            <Tab> Overview </Tab>
            <Tab> Routes </Tab>
          </TabList>
          <TabPanel>
            <FerryTicketAgencyOverView />
          </TabPanel>
          <TabPanel>
            <FerryTicketAgencyRoutes />
          </TabPanel>
        </Tabs>
        <Footer />
      </div>
    );
  }
}

export default FerryTicketAgency;
